import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SubscribeConfirmedComponent} from './component/subscribe-confirmed';
import {HomeComponent} from './component/home';
import {AuthorComponent} from './component/author';
import {AdditionalComponent} from './component/additional';
import {SearchComponent} from './component/search';
import {NotFoundComponent} from './component/not-found/not-found.component';
import {PostPageComponent} from './component/post-page';
import {PostPreviewComponent} from './component/post-preview/post-preview.component';
import {NotFoundBlockedComponent} from './component/not-found-blocked/not-found-blocked.component';
import {BlockedRoutesGuard} from '@thebell/common/services/api/blocked-routes';
import {LayoutComponent} from './component/layout/layout.component';
import {SubscribersAuthGuard} from '@thebell/common/services/api/auth/common-jwt';

export const routesTogether = [
  {
    path: '',
    loadChildren: () => import('./component/donate-page/donate-page.module').then((m) => m.DonatePageModule),
    data: {name: 'donates'},
  },
];

export const routesInvest = [
  {
    path: '',
    loadChildren: () => import('./component/invest-landing/invest-landing.module').then((m) => m.InvestLandingModule),
    data: {name: 'invest'},
  },
];

export const routes: Routes = [
  {
    path: 'together',
    loadChildren: () => import('./component/donate-page/donate-page.module').then((m) => m.DonatePageModule),
    data: {name: 'donates'},
  },
  {
    path: 'cloudpayments-result/success',
    loadChildren: () => import('./component/order-in-process/order-in-process.module').then((m) => m.OrderInProcessModule),
    data: {name: 'success'},
  },
  {
    path: 'cloudpayments-result/failure',
    loadChildren: () => import('./component/order-in-process/order-in-process.module').then((m) => m.OrderInProcessModule),
    data: {name: 'failure'},
  },
  {
    path: 'invest',
    loadChildren: () => import('./component/invest-landing/invest-landing.module').then((m) => m.InvestLandingModule),
    data: {name: 'invest'},
  },
  {
    path: 'newsletters',
    loadChildren: () => import('./component/newsletters-landing/newsletters-landing.module').then((m) => m.NewslettersLandingModule),
    data: {name: 'newsletters'},
  },
  {
    path: 'paywall',
    loadChildren: () => import('./component/paywall-landing/paywall-landing.module').then((m) => m.PaywallLandingModule),
    data: {name: 'paywall'},
  },
  {
    path: 'invest#subscription',
    loadChildren: () => import('./component/invest-landing/invest-landing.module').then((m) => m.InvestLandingModule),
    data: {name: 'invest'},
  },
  {
    path: 'en/together',
    loadChildren: () => import('./component/donate-page-en/donate-page-en.module').then((m) => m.DonatePageEnModule),
    data: {name: 'donates-en'},
  },
  {
    path: 'invest',
    loadChildren: () => import('./component/invest-landing/invest-landing.module').then((m) => m.InvestLandingModule),
    data: {name: 'invest'},
  },
  // {
  //   path: 'subscriptions',
  //   loadChildren: () =>
  //     import('./component/paid-subscription-page/paid-subscription-page.module').then(
  //       (m) => m.PaidSubscriptionPageModule
  //     ),
  // },
  {
    path: 'profile',
    loadChildren: () => import('./component/profile-page/profile-page.module').then((m) => m.ProfilePageModule),
    data: {name: 'profile'},
  },
  {
    path: 'account',
    canActivate: [SubscribersAuthGuard],
    loadChildren: () => import('./component/account/account.module').then((m) => m.AccountModule),
    data: {name: 'account'},
  },
  {
    path: 'account/verification-failed',
    loadChildren: () => import('./component/account-verification-failed/account-verification-failed.module').then((m) => m.AccountVerificationFailedModule),
    data: {name: 'Верификация не удалась'},
  },
  {
    path: 'account/login',
    loadChildren: () => import('./component/account-login/account-login.module').then((m) => m.AccountLoginModule),
    data: {name: 'login'},
  },
  {
    path: 'account/create',
    loadChildren: () => import('./component/account-create/account-create.module').then((m) => m.AccountCreateModule),
    data: {title: 'Создать учетную запись'},
  },
  {
    path: 'account/reset-pass',
    loadChildren: () => import('./component/account-reset-password/account-reset-password.module').then((m) => m.AccountResetPasswordModule),
    data: {title: 'Восстановить пароль'},
  },
  {
    path: 'account/password/restore/:token',
    loadChildren: () => import('./component/account-restore-password/account-restore-password.module').then((m) => m.AccountRestorePasswordModule),
    data: {title: 'Установить новый пароль'},
  },
  {
    path: 'account/change-password',
    canActivate: [SubscribersAuthGuard],
    loadChildren: () => import('./component/account-change-password/account-change-password.module').then((m) => m.AccountChangePasswordModule),
    data: {title: 'Изменить пароль'},
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      // {path: '', redirectTo: '/', pathMatch: 'full'}, // Обрезал парамеры из URL, поэтому отключен
      {path: '', component: HomeComponent, data: {name: 'main'}},
      {path: 'tag/:tag', component: HomeComponent, data: {name: 'tag'}}, // +
      {path: 'category/:category', component: HomeComponent, data: {name: 'category'}}, // +
      {path: 'spec/:slug', component: HomeComponent, data: {name: 'spec'}},
      {path: 'author/:slug', component: AuthorComponent, data: {name: 'author'}},
      {path: 'privacy-policy', component: AdditionalComponent, data: {wpId: 89856}},
      {path: 'refund-policy', component: AdditionalComponent, data: {wpId: 146950}},
      {path: 'about', component: AdditionalComponent, data: {wpId: 111007}},
      {path: 'team', component: AdditionalComponent, data: {wpId: 90197}},
      {path: 'subscribe-confirmed', component: SubscribeConfirmedComponent},
      {path: 'search/:query', component: SearchComponent, data: {name: 'search'}},
      {path: '404', component: NotFoundComponent},
      {path: '404-blocked', component: NotFoundBlockedComponent},
      {path: 'post-preview', component: PostPreviewComponent},
      {path: ':slug', component: PostPageComponent, canActivate: [BlockedRoutesGuard], data: {name: 'post'}},
      {path: '**', component: NotFoundComponent},
    ],
  },
];

let resultRoutes = routes;
if (typeof window !== 'undefined') {
  if (window.location.hostname === 'thebelltogether.online') {
    resultRoutes = routesTogether;
  }
  if (window.location.hostname === 'thebellinvest.pro') {
    resultRoutes = routesInvest;
  }
}

console.log(resultRoutes);

@NgModule({
  imports: [
    RouterModule.forRoot(resultRoutes, {
      // scrollPositionRestoration: 'top',
      // anchorScrolling: 'enabled',
      // initialNavigation: 'enabledBlocking',
      // scrollOffset: [0, 64]
    }),
  ],
  exports: [RouterModule],
})
export class ClientAppRoutingModule {}
