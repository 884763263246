import { AuthScopesEnum, JwtAccessPayload } from './contracts';

export class UserContext {
  readonly scopes: Set<string>;

  public constructor(
    readonly id: number,
    readonly email: string,
    readonly provider: string,
    scopes?: string[],
    readonly jwt?: Omit<JwtAccessPayload, 'extra'>
  ) {
    this.scopes = new Set<string>(scopes || []);
  }

  get isVerifiedEmail(): boolean {
    return this.scopes.has(AuthScopesEnum.SUBSCRIBERS_VERIFIED);
  }

  static deserialize(payload: JwtAccessPayload): UserContext {
    const { extra, ...jwt } = payload;
    return new this(extra.id, extra.email, extra.provider, extra.scopes, jwt);
  }

  serialize(): JwtAccessPayload {
    return {
      ...this.jwt,
      extra: {
        id: this.id,
        email: this.email,
        provider: this.provider,
        scopes: Array.from(this.scopes)
      }
    };
  }
}
