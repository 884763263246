import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {DetectedScreenChangeService} from '@thebell/common/services/utils/detected-screen-change';
import {DetectedRouteChangeService} from '@thebell/common/services/utils/detected-route-change';
import {filter} from "rxjs/operators";
import {ClientAuthService} from "../../../../../../libs/common/services/api/client-auth/src";
import {AccountLoginService} from "../../component/account-login/account-login.service";
import {AuthClient} from '@thebell/common/services/api/auth/common-jwt';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ClientAuthService, AccountLoginService],
  animations: [trigger('openCloseMenu', [state('true', style({
    height: '*', opacity: 1,
  })), state('false', style({
    height: 0, opacity: 0, display: 'none',
  })), transition('true => false', [animate('300ms ease-in')]), transition('false => true', [style({display: 'block'}), animate('300ms ease-in')]),]), trigger('openCloseSearch', [state('horizontalOpen', style({
    height: '*', width: '419px', opacity: 1, overflow: 'hidden',
  })), state('horizontalClose', style({
    height: '*', width: 0, opacity: 0, display: 'none', overflow: 'hidden',
  })), state('verticalOpen', style({
    height: '*', opacity: 1,
  })), state('verticalClose', style({
    height: '*', opacity: 0, display: 'none',
  })), transition('horizontalOpen => horizontalClose', [animate('300ms ease-in')]), transition('horizontalClose => horizontalOpen', [style({display: 'flex'}), animate('300ms ease-in')]), transition('verticalOpen => verticalClose', [animate('300ms ease-in')]), transition('verticalClose => verticalOpen', [style({display: 'flex'}), animate('300ms ease-in')]),]), trigger('openCloseTags', [state('horizontalClose', style({
    opacity: 1, overflow: 'hidden', display: 'flex',
  })), state('horizontalOpen', style({
    opacity: 0, display: 'none', overflow: 'hidden',
  })), transition('horizontalOpen => horizontalClose', [animate('300ms ease-in')]), transition('horizontalClose => horizontalOpen', [style({display: 'flex'}), animate('300ms ease-in')]),]), trigger('menuAnimation', [state('true', style({marginTop: '4px'})), state('false', style({marginTop: '10px'})), transition('true => false', [animate('100ms ease-in')]), transition('false => true', [animate('100ms ease-out')]),]),],
})
export class HeaderComponent implements OnDestroy {
  activeMenuItem: string;
  @ViewChild('searchInput', {static: false}) searchRef: ElementRef;

  menuIsOpen = false;
  searchIsOpen = false;
  searchIsOpenState = 'horizontalClose';
  subscribe: Subscription;
  detectedRouteChangeSubject: Subscription;
  searchValue = '';
  email = '';
  isUserAuthorized = false;

  constructor(
    private detectedScreenChangeService: DetectedScreenChangeService,
    private router: Router,
    private detectedRouteChangeService: DetectedRouteChangeService,
    private readonly authClient: AuthClient
  ) {
    this.setAnimationDirection(this.detectedScreenChangeService.getScreenType());
    this.subscribe = this.detectedScreenChangeService.screenTypeChanged.subscribe((screenType) => {
      this.setAnimationDirection(screenType);
    });
    this.detectedRouteChangeSubject = this.detectedRouteChangeService.subject.subscribe((currentRoute) => {
      if (currentRoute) this.setSearchView(currentRoute);
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateActiveMenuItem();
    });
    this.checkAuthorization();
  }

  checkAuthorization() {
    if (this.authClient.isAuthSubscriber) {
      this.email = this.authClient.userContext.email;
      this.isUserAuthorized = true;
    }
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.detectedRouteChangeSubject.unsubscribe();
  }

  getFirstLetter(email: string): string {
    return email ? email.charAt(0).toUpperCase() : '!';
  }

  setAnimationDirection(screenType) {
    if (screenType === 'desktop') {
      this.searchIsOpenState = this.searchIsOpenState.replace('vertical', 'horizontal');
    } else {
      this.searchIsOpenState = this.searchIsOpenState.replace('horizontal', 'vertical');
    }
  }

  changeOpenMenuState(b = !this.menuIsOpen) {
    this.menuIsOpen = b;
    if (this.menuIsOpen) ga('send', 'event', 'topbar', 'click_menu');
    if (this.menuIsOpen) dataLayer.push({
      'event': 'event', 'eventCategory': 'topbar', 'eventAction': 'click_menu'
    });

  }

  updateActiveMenuItem(): void {
    const currentUrl = this.router.url;
    if (currentUrl === '/' || /^\/\?.*/.test(currentUrl)) {
      this.activeMenuItem = 'main';
    } else {
      const parts = currentUrl.split('/');
      if (parts.length > 1) {
        this.activeMenuItem = parts[parts.length - 1];
      }
    }
  }

  isMenuItemActive(menuItem: string): boolean {
    return this.activeMenuItem === menuItem;
  }


  changeOpenSearchState() {
    this.searchIsOpen = !this.searchIsOpen;

    if (this.searchIsOpen) ga('send', 'event', 'main_page', 'search_open');
    if (this.searchIsOpen) dataLayer.push({
      'event': 'event', 'eventCategory': 'main_page', 'eventAction': 'search_open'
    });

    switch (this.searchIsOpenState) {
      case 'horizontalClose':
        this.searchIsOpenState = 'horizontalOpen';
        break;
      case 'horizontalOpen':
        this.searchIsOpenState = 'horizontalClose';
        break;
      case 'verticalClose':
        this.searchIsOpenState = 'verticalOpen';
        break;
      case 'verticalOpen':
        this.searchIsOpenState = 'verticalClose';
        break;
    }
    // нужен потому что анимация 300 мс
    setTimeout(() => {
      this.searchRef.nativeElement.focus();
      this.searchRef.nativeElement.click();
    }, 300);
  }

  setSearchView(route) {
    if (route.snapshot.data.name && route.snapshot.data.name === 'search') {
      this.searchValue = route.snapshot.params.query;
      this.searchIsOpenState = this.searchIsOpenState.replace('Close', 'Open');
      this.searchIsOpen = true;
    } else {
      this.searchValue = '';
      this.searchIsOpenState = this.searchIsOpenState.replace('Open', 'Close');
      this.searchIsOpen = false;
    }
  }

  search() {
    if (!this.checkSearchIsEmpty()) {
      ga('send', 'event', 'main_page', 'search_use');
      dataLayer.push({
        'event': 'search', 'category': 'search', 'search_term': this.searchValue
      });
      this.searchRef.nativeElement.blur();
      this.router.navigate(['search', this.searchValue]);
    }
  }

  keyup(event) {
    if (event.key === 'Enter') this.search();
  }

  checkSearchIsEmpty() {
    return this.searchValue.split(' ').join('').length === 0;
  }

  onColMenuItemClick(itemName: string) {
    this.activeMenuItem = itemName;
    ga('send', 'event', 'topbar', `topbar_${itemName}`);
    dataLayer.push({
      'event': 'header_menu_click', 'category': 'header', 'label': itemName
    });
  }
}
