import { AuthClient } from '../auth.client';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthScopesEnum } from '../contracts';

@Injectable({
  providedIn: 'root',
})
export class SubscribersAuthGuard implements CanActivate {
  constructor(private authClient: AuthClient, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authClient.isAuthSubscriber) {
      return true;
    } else {
      this.router.navigate(['account/login'], { queryParams: { loginAgain: true, } }).then();
      return false;
    }
  }
}
