import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
  AssetListDto,
  AssetListInputDto,
  AuthorListDto,
  AuthorListInput,
  AuthorListInputDto,
  BindAssetInput,
  CreatePostInput,
  CreatePostInputDto,
  ExternalPostListDto,
  ExternalPostListInputDto,
  FeedSettingsGqlDto,
  FeedSettingsInputDto,
  InputFeedSettingsDto,
  IWidgetStyle,
  Post,
  PostCreateQueryDto,
  PostDeleteQueryDto,
  PostList,
  PostListDto,
  PostListInput,
  PostListInputDto,
  PostMeta,
  PostMetaInput,
  PostMetaQueryDto,
  PostMetaWidget,
  PostMetaWidgetInput,
  PostMetaWidgetQueryDto,
  PostQueryDto,
  PostUpdateQueryDto,
  PublishedPostQueryDto,
  PublishedPost,
  UpdatePostInput,
  UpdatePostInputDto,
  UpdatePostMetaDto,
  UpdatePostMetaWidgetDto,
  UpdateWidgetStyleDto,
  WidgetStyleQueryDto,
  AssetsQueryDto,
  PostPaidContentQueryDto,
  PostPaidContent,
} from '@thebell/data-transfer-objects';

import {TypeSortObject} from '../shared/sorts';
import {
  AUTHORS_GET_QUERY,
  EXTERNAL_POSTS_BY_CATEGORY,
  FEED_SETTINGS,
  POST_ASSETS,
  POST_CREATE_QUERY,
  POST_DELETE_QUERY,
  POST_GET_QUERY,
  POST_META_GET_QUERY,
  POST_META_UPDATE,
  POST_META_WIDGET_GET_QUERY,
  POST_META_WIDGET_UPDATE,
  POST_PUBLIC_FORCE_QUERY,
  POST_PUBLIC_QUERY,
  POST_RETURN_TO_DRAFT_QUERY,
  POST_STYLE_GET_QUERY,
  POST_STYLE_UPDATE,
  POST_TOPIC_GET_QUERY,
  POST_UPDATE_QUERY,
  POSTS_GET_QUERY,
  POST_PUBLISHED_GET_QUERY,
  BIND_ASSETS_QUERY,
  POST_PAID_CONTENT_GET_QUERY,
} from './post.queries';
import {DocumentNode} from '@apollo/client';
import {ErrorService} from '../shared/error.service';
import {parsePaginationQuery} from '@thebell/graphql-utils';
import {StatusCodeError} from '@thebell/erorrs';
import {StatusCodes} from '@thebell/erorrs';
export enum PostAction {
  POST_ACTION_CREATE = 'createPost',
  POST_ACTION_UPDATE = 'updatePost',
  POST_ACTION_PUBLISH = 'postPublic',
  POST_ACTION_PUBLISH_FORCE = 'postPublicForce',
  POST_ACTION_RETURN_TO_DRAFT = 'postReturnToDraft',
}

const QUERY_MAP: {[key in PostAction]: DocumentNode} = {
  [PostAction.POST_ACTION_CREATE]: POST_CREATE_QUERY,
  [PostAction.POST_ACTION_UPDATE]: POST_UPDATE_QUERY,
  [PostAction.POST_ACTION_PUBLISH]: POST_PUBLIC_QUERY,
  [PostAction.POST_ACTION_PUBLISH_FORCE]: POST_PUBLIC_FORCE_QUERY,
  [PostAction.POST_ACTION_RETURN_TO_DRAFT]: POST_RETURN_TO_DRAFT_QUERY,
};

@Injectable({
  providedIn: 'root',
})
export class PostApiService {
  api: string;

  constructor(private apollo: Apollo, private errorService: ErrorService) {}

  posts(paginatorCursor = '', pageSize = 100, filters: PostListInput = {}, sort?: TypeSortObject) {
    return this.apollo
      .watchQuery<PostListDto, PostListInputDto>({
        query: POSTS_GET_QUERY,
        variables: {
          first: pageSize,
          after: paginatorCursor,
          orderDirection: sort?.value,
          orderBy: sort?.name,
          lang: 'ru',
          ...filters,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((res) => parsePaginationQuery<PostList>(res.data.posts)));
  }

  topicPost(id: number) {
    return this.apollo
      .watchQuery<{post: PostQueryDto; postWidgetStyle: WidgetStyleQueryDto}, {id: number}>({
        query: POST_TOPIC_GET_QUERY,
        variables: {id: +id},
      })
      .valueChanges.pipe(map((res) => res.data));
  }

  post(id: number, key = ''): Observable<Post> {
    return this.apollo
      .watchQuery<PostQueryDto, {id: number}>({
        query: POST_GET_QUERY,
        variables: {
          id: +id,
        },
        context: {
          headers: {
            'access-key': key,
          },
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map((res) => {
          // А это костыль. Потому что редакция постоянно косячит, и не проверяет ссылки. Приходится сразу готовить
          // им абсолютные ссылки, т к они берут инфу для почтовых рассылок именно из админки, и ничего не проверяют.
          // Разговаривать с ними бесполезно, не помогает.
          const post = { ...res.data.post };

          if (post.content) {
            post.content = post.content.replace(/href=\"\//g, 'href=\"https://thebell.site/');
          }

          if (post.raw_content) {
            post.raw_content = post.raw_content.replace(/href=\"\//g, 'href=\"https://thebell.site/');
          }

          if (post.content_rss) {
            post.content_rss = post.content_rss.replace(/href=\"\//g, 'href=\"https://thebell.site/');
          }

          if (post.content_amp) {
            post.content_amp = post.content_amp.replace(/href=\"\//g, 'href=\"https://thebell.site/');
          }

          return post;
        })
      );
  }

  postPaidContent(id: number): Observable<PostPaidContent> {
    return this.apollo
      .watchQuery<PostPaidContentQueryDto, {id: number}>({
        query: POST_PAID_CONTENT_GET_QUERY,
        variables: {
          id: +id,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((res) => res.data.postPaidContent));
  }

  publishedPost(slug: string): Observable<PublishedPost> {
    return this.apollo
      .query<PublishedPostQueryDto, {slug: string}>({
        query: POST_PUBLISHED_GET_QUERY,
        variables: {slug},
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      })
      .pipe(
        map((res) => {
          if (res.errors?.length) {
              console.log("POST ERROR SLUG: ", slug)
            throw new StatusCodeError(StatusCodes.NOT_FOUND);
          }
          return res.data.published_post;
        })
      );
  }

  meta(id: number, cache = false) {
    return this.apollo
      .query<PostMetaQueryDto, {postId: number}>({
        query: POST_META_GET_QUERY,
        variables: {postId: +id},
        fetchPolicy: cache ? 'cache-first' : 'network-only',
      })
      .pipe(map((res) => res.data.postMeta));
  }

  saveMeta(data: Partial<PostMetaInput>) {
    return this.apollo.mutate<Partial<PostMeta>, UpdatePostMetaDto>({
      mutation: POST_META_UPDATE,
      variables: {postMeta: data},
    });
  }

  metaWidget(id: number) {
    return this.apollo
      .watchQuery<PostMetaWidgetQueryDto, {postId: number}>({
        query: POST_META_WIDGET_GET_QUERY,
        variables: {postId: +id},
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((res) => res.data.postMetaWidget));
  }

  saveMetaWidget(data: PostMetaWidgetInput) {
    return this.apollo.mutate<Partial<PostMetaWidget>, UpdatePostMetaWidgetDto>({
      mutation: POST_META_WIDGET_UPDATE,
      variables: {postMetaWidget: data},
    });
  }

  style(id: number) {
    return this.apollo
      .watchQuery<WidgetStyleQueryDto, {postId: number}>({
        query: POST_STYLE_GET_QUERY,
        variables: {postId: +id},
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((res) => res.data.postWidgetStyle));
  }

  saveStyle(data: IWidgetStyle) {
    return this.apollo.mutate<Partial<IWidgetStyle>, UpdateWidgetStyleDto>({
      mutation: POST_STYLE_UPDATE,
      variables: {postWidgetStyle: data},
    });
  }

  saveAssets(bindAssetInput: BindAssetInput, postId: number) {
    return this.apollo
      .mutate<AssetsQueryDto, {bindAssetInput: BindAssetInput; postId: number}>({
        mutation: BIND_ASSETS_QUERY,
        variables: {
          bindAssetInput,
          postId,
        },
      })
      .pipe(map((res) => res.data.bindAssets));
  }

  createPost(
    post: CreatePostInput,
    feedSettings: InputFeedSettingsDto,
    metaWidget: PostMetaWidgetInput,
    meta: PostMetaInput,
    bindAsset: BindAssetInput
  ) {
    return this.apollo
      .mutate<PostCreateQueryDto, CreatePostInputDto>({
        mutation: POST_CREATE_QUERY,
        variables: {
          createPostInput: post,
          postMetaInput: meta,
          postMetaWidgetInput: metaWidget,
          bindAssetInput: bindAsset,
          feedSettingsInput: feedSettings as any,
        },
        refetchQueries: [
          {
            query: POSTS_GET_QUERY,
            variables: {
              after: '',
              author: '',
              created_at: null,
              first: 100,
              lang: 'ru',
              orderBy: 'id',
              orderDirection: 'DESC',
              published_at: null,
              status: '',
              title: '',
            },
          },
        ],
        errorPolicy: 'all',
      })
      .pipe(
        tap((res) => {
          if (res.errors) {
            this.errorService.transformErrorFromApi(res.errors);
          }
          return res;
        }),
        map((el) => {
          return el.data.createPost.id;
        })
      );
  }

  deletePost(id: number) {
    return this.apollo.mutate<PostDeleteQueryDto, {id: number}>({
      mutation: POST_DELETE_QUERY,
      variables: {id},
    });
  }

  savePost(
    post: UpdatePostInput,
    feedSettings: InputFeedSettingsDto,
    metaWidget: PostMetaWidgetInput,
    meta: PostMetaInput,
    bindAsset: BindAssetInput,
    action: PostAction
  ) {
    const QUERY = QUERY_MAP[action];
    return this.apollo
      .mutate<PostUpdateQueryDto, UpdatePostInputDto>({
        mutation: QUERY,
        variables: {
          updatePostInput: post,
          postMetaInput: meta,
          postMetaWidgetInput: metaWidget,
          bindAssetInput: bindAsset,
          feedSettingsInput: feedSettings,
        },
        refetchQueries: [
          {
            query: POSTS_GET_QUERY,
            variables: {
              after: '',
              author: '',
              created_at: null,
              first: 100,
              lang: 'ru',
              orderBy: 'id',
              orderDirection: 'DESC',
              published_at: null,
              status: '',
              title: '',
            },
          },
          {
            query: POST_GET_QUERY,
            variables: {id: +post.id},
          },
        ],
        errorPolicy: 'all',
      })
      .pipe(
        tap((res) => {
          if (res.errors) {
            this.errorService.transformErrorFromApi(res.errors);
          }
          return res;
        }),
        map((el) => {
          return el.data[action];
        })
      );
  }

  authors(paginatorCursor = '', pageSize = 100, filters: AuthorListInput = {name: ''}) {
    return this.apollo
      .watchQuery<AuthorListDto, AuthorListInputDto>({
        query: AUTHORS_GET_QUERY,
        variables: {
          first: pageSize,
          after: paginatorCursor,
          ...filters,
        },
      })
      .valueChanges.pipe(map((res) => parsePaginationQuery(res.data.authors)));
  }

  assets(id: number) {
    return this.apollo
      .watchQuery<AssetListDto, AssetListInputDto>({
        query: POST_ASSETS,
        variables: {
          id: +id,
          type: 'post',
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((res) => res.data.assets));
  }

  externalPosts(paginatorCursor = '', categoryTitle: string, pageSize = 20) {
    return this.apollo
      .watchQuery<ExternalPostListDto, ExternalPostListInputDto>({
        query: EXTERNAL_POSTS_BY_CATEGORY,
        variables: {
          first: pageSize,
          after: paginatorCursor,
          category_title: categoryTitle,
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((res) => ({
          posts: res.data ? res.data.external_posts.edges.map((edge) => edge.node) : [],
          cursor: res.data ? res.data.external_posts.edges[res.data.external_posts.edges?.length - 1]?.cursor : null,
          hasNextPage: res.data ? res.data.external_posts.pageInfo?.hasNextPage : null,
        }))
      );
  }

  feedSettings(id: number, feed_key: string) {
    return this.apollo
      .watchQuery<FeedSettingsGqlDto, FeedSettingsInputDto>({
        query: FEED_SETTINGS,
        variables: {
          post_id: +id,
          feed_key,
        },
      })
      .valueChanges.pipe(
        map((res) =>
          res.data.feed_settings.map((el) => {
            return {key: el.key, value: el.value};
          })
        )
      );
  }

  getDefaultAuthor() {
    return this.apollo
      .query({
        query: gql`
          query getDefaultAuthor {
            authorByName(name: "Редакция The Bell") {
              id
              name_ru
            }
          }
        `,
      })
      .pipe(map((res) => (res.data as any).authorByName));
  }
}
