import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {
  GqlDto,
} from "@thebell/data-transfer-objects";
import {
  LoginAccountDto,
  LoginAccountInputDto
} from "../../../../../api/src/domains/admin/client-auth/dto/login-account.dto";


const loginAccountMutation = gql`
  mutation initLoginAccount($input: LoginAccountInput!) {
    initLoginAccount(loginAccountInput: $input) {
      result
    }
  }
`;

@Injectable()
export class AccountLoginService {
  constructor(private apollo: Apollo) {
  }

  initLoginAccount(input: LoginAccountInputDto) {
    return this.apollo
      .mutate<GqlDto<'loginAccount', LoginAccountDto>>({
        mutation: loginAccountMutation,
        variables: {input},
      })
      .pipe(map((res) => res.data.loginAccount));
  }
}
