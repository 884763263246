export enum UserProviderEnum {
  SUBSCRIBERS = 'subscribers'
}

export enum AuthScopesEnum {
  SUBSCRIBERS = 'subscribers',
  SUBSCRIBERS_VERIFIED = 'subscribers.verified'
}

export const SSR_TOKEN_EXPIRED_COOKIE = 'ssr-token-expired';
export const ACCESS_TOKEN_ITEM = 'c_jwt_access_token';
