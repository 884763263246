import {Environment, EnvironmentService} from '@thebell/common/services/core/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientAuthService {
  private environment: Environment;

  constructor(private envService: EnvironmentService, private http: HttpClient) {
    this.environment = this.envService.getEnvironment();
  }

  changePassword(body: {
    email: string; old_password: string; new_password: string;
  }): Observable<{ error: boolean; msg: string }> {
    return this.http.post<{
      error: boolean;
      msg: string
    }>(`${this.environment.baseUrl}api/v1/clients/passwd/change`, body);
  }

  resetPass(body: { email: string }) {
    return this.http.post<{
      error: boolean;
      msg: string
    }>(`${this.environment.baseUrl}api/v1/clients/passwd/reset`, body);
  }

  restorePass(body: { password: string; token: string }) {
    return this.http.post<{
      error: boolean;
      msg: string
    }>(`${this.environment.baseUrl}api/v1/clients/passwd/set`, body);
  }

  createUser(body: CreateUserRequest) {
    return this.http.post<{ error: boolean; msg: string }>(`${this.environment.apiAdminUrl}clients`, body);
  }

  verifyEmail(body: { email: string, domain: string }) {
    return this.http.post<{ error: boolean; msg: string }>(`${this.environment.apiAdminUrl}verify-email`, body);
  }

  sendMailing(body: { email: string, link: string }) {
    return this.http.post<{ error: boolean; msg: string }>(`${this.environment.apiAdminUrl}send-mailing`, body);
  }
}

export interface CreateUserRequest {
  email: string;
  password?: string;
  receiver?: string;
}
