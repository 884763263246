import { AuthClient } from './auth.client';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authClient: AuthClient,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('skip')) {
      const headers = req.headers.delete('skip');
      return next.handle(req.clone({ headers }));
    }

    return this.authClient.getAccessToken().pipe(
      switchMap((token) => {
        if (!token) {
          return next.handle(req);
        }

        return next.handle(this.addAuthToken(req, token)).pipe(
          mergeMap((event) => {
            if (event instanceof HttpResponse && this.hasUnauthorizedStatus(event)) {
              // Токен мог быть отозван (принудительная ротация), пробуем обновить
              return this.authClient.refreshAccessToken().pipe(
                switchMap((token) => {
                  if (token) {
                    return next.handle(this.addAuthToken(req, token));
                  } else if (this.isOptionalAuthorization(event)) {
                    return next.handle(req);
                  } else {
                    this.handleUnauthorized();
                    return of(event);
                  }
                })
              );
            }

            return of(event);
          })
        );
      })
    );
  }

  private addAuthToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private hasUnauthorizedStatus(response: HttpResponse<any>) {
    if (response.status === 401) {
      return true;
    }

    if (Array.isArray(response.body?.errors)) {
      return !!response.body.errors.find((error: any) => error?.extensions?.statusCode === 401);
    }

    return false;
  }

  private isOptionalAuthorization(response: HttpResponse<any>) {
    if (Array.isArray(response.body?.errors)) {
      return !!response.body.errors.find((error: any) => error?.extensions?.errors?.optionalAuth === true);
    }
  }

  private handleUnauthorized() {
    if (isPlatformBrowser(this.platformId)) {
      this.authClient.logout().finally(() => {
        this.router.navigateByUrl('/account/login', { replaceUrl: true }).then();
      });
    }
  }
}
