import {
  CredentialsDto,
  TokenResponseDto,
  UserProviderEnum,
  RecaptchaError,
  AuthScopesEnum,
  ACCESS_TOKEN_ITEM,
  SSR_TOKEN_EXPIRED_COOKIE
} from './contracts';
import { UserContext } from './user-context';
import { EnvironmentService } from '@thebell/common/services/core/environment';

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { default as jwtDecode } from 'jwt-decode';

@Injectable()
export class AuthClient {
  private readonly authApi: string;
  private hasRefreshTokenProcess = false;
  private accessTokenSubject = new ReplaySubject<string | null>(1);
  private readonly userContextSubject = new BehaviorSubject<null | UserContext>(null);

  constructor(
    private readonly cookie: CookieService,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, any>,
    @Inject('localStorage') private readonly localStorage: Storage,
    envService: EnvironmentService
  ) {
    const authApi = envService.getEnvironment().authApi;
    this.authApi = authApi.endsWith('/') ? authApi : authApi + '/';

    const currentToken = this.getAccessTokenFromStorage();
    this.accessTokenSubject.next(currentToken);

    if (!currentToken) {
      return;
    }

    try {
      this.userContextSubject.next(UserContext.deserialize(jwtDecode(currentToken)));
    } catch (e) {
      console.info('Invalid access token:', e);
    }

    const isSsrAccessTokenExpired = this.cookie.get(SSR_TOKEN_EXPIRED_COOKIE);
    if (isSsrAccessTokenExpired || this.isExpiredAccessToken()) {
      this.cookie.delete(SSR_TOKEN_EXPIRED_COOKIE);

      this.refreshAccessToken().subscribe((token) => {
        if (!token) {
          this.dropSession();
        } else if (isSsrAccessTokenExpired) {
          window.location.reload();
        }
      });
    }
  }

  /**
   * @return true - if credentials valid, false - otherwise;
   * @throws RecaptchaError if invalid recaptcha
   */
  async login(
    provider: UserProviderEnum,
    credentials: CredentialsDto,
    recaptcha?: string
  ): Promise<boolean> {
    return fetch(`${this.authApi}login/${provider}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({...credentials, recaptcha }),
      credentials: 'include'
    }).then((response) => {
      if (response.ok) {
        return response.json().then((data: TokenResponseDto) => {
          this.setAccessToken(data.accessToken);
          return this.isAuthSubscriber;
        });
      } else if (response.status === 401) {
        return false;
      }

      return response.json().then((data) => {
        if (Array.isArray(data.body)) {
          const recaptchaField = data.body.find(
            (error) => error.body?.property === 'recaptcha'
          );
          if (recaptchaField) {
            throw new RecaptchaError();
          }
        }

        throw data;
      });
    });
  }

  getAccessToken(): Observable<null | string> {
    if (this.hasRefreshTokenProcess || this.isExpiredAccessToken()) {
      return this.refreshAccessToken();
    }

    return this.accessTokenSubject.pipe(take(1));
  }

  get userContext(): null | UserContext {
    return this.userContextSubject.value;
  }

  get $userContext(): Observable<null | UserContext> {
    return this.userContextSubject.asObservable();
  }

  get isAuthSubscriber(): boolean {
    return this.userContext?.scopes.has(AuthScopesEnum.SUBSCRIBERS);
  }

  refreshAccessToken(): Observable<null | string> {
    if (!this.userContext || !isPlatformBrowser(this.platformId)) {
      return of(null);
    }

    if (!this.hasRefreshTokenProcess) {
      this.hasRefreshTokenProcess = true;
      this.accessTokenSubject = new ReplaySubject<null | string>(1);

      fetch(`${this.authApi}refresh`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((data: TokenResponseDto) => {
              this.setAccessToken(data.accessToken);
            });
          } else {
            if (response.status !== 401) {
              console.error(response);
            }
            this.dropSession();
          }
        })
        .catch((e) => {
          console.error(e);
          this.dropSession();
        });
    }

    return this.accessTokenSubject.pipe(take(1));
  }

  public setAccessToken(token: string) {
    const userContext = UserContext.deserialize(jwtDecode(token));

    // todo magic number, move to config
    // Куку храним долго, столько же или больше чем refresh_token,
    // она является маркером того что пользователь был авторизован,
    // т.к. refresh_token недоступен из js
    this.cookie.set(ACCESS_TOKEN_ITEM, token, new Date(new Date().getTime() + 60*60*24*365*1000), '/', null, true);

    this.userContextSubject.next(userContext);
    this.accessTokenSubject.next(token);
  }

  async logout() {
    const accessToken = await this.getAccessToken().toPromise();
    if (!accessToken) {
      return;
    }

    this.dropSession();

    fetch(`${this.authApi}logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response);
        }
      })
      .catch((error) => console.error(error));
  }

  public isExpiredAccessToken(): boolean {
    return this.userContext
      && this.userContext.jwt.exp * 1000 - new Date().getTime() <= 30 * 1000;
  }

  private dropSession() {
    this.cookie.delete(ACCESS_TOKEN_ITEM);
    this.userContextSubject.next(null);
    this.accessTokenSubject.next(null);
  }

  protected getAccessTokenFromStorage(): null | string {
    // Токен храниться в cookie, но при ssr AuthMiddleware перекладывает его в
    // localStorage (polyfill), т.к. cookie не прокидываются из запроса
    // todo provide cookie for ssr
    const token = this.cookie.get(ACCESS_TOKEN_ITEM)
      || this.localStorage.getItem(ACCESS_TOKEN_ITEM);
    return token && token.length > 0 ? token : null;
  }
}
