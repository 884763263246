import {BrowserModule, makeStateKey, TransferState} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {NgModule, ErrorHandler, APP_INITIALIZER, PLATFORM_ID} from '@angular/core';
import {Router, Scroll} from '@angular/router';
import * as Sentry from '@sentry/angular';
import {CommonUiModule} from './common-ui.module';
import {WIDGET_DATA} from '@thebell/common/injection-tokens/widget-data';
import {Error404Interceptor} from '@thebell/common/interceptors/error404';
import {ENV} from '@thebell/common/services/core/environment';
import {ClientAppRoutingModule} from './client-app-routing.module';
import {AppComponent} from './app.component';
import {BellclubComponent} from './shared/menu/bellclub/bellclub.component';
import {SubscribeConfirmedComponent} from './component/subscribe-confirmed';
import {FooterComponent} from './shared/footer/footer.component';
import {FooterMenuComponent} from './shared/footer-menu/footer-menu.component';
import {AdditionalComponent} from './component/additional';
import {AuthorComponent} from './component/author';
import {HomeComponent} from './component/home';
import {HeaderComponent} from './shared/header';
import {MenuComponent} from './shared/menu';
import {AdFoxComponent} from './shared/adfox-top';
import {PostPageComponent} from './component/post-page';
import {SubscriptionPageComponent} from './component/subscription-page';
import {SearchComponent} from './component/search';
import {environment} from '../environments/environment';
import {InSchoolComponent} from './shared/menu/in-school/in-school.component';
import {JsonLdModule} from 'ngx-seo';
import {PostPreviewComponent} from './component/post-preview/post-preview.component';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {CookieService} from 'ngx-cookie-service';
import {HttpLink, HttpLinkHandler} from 'apollo-angular/http';
import {InMemoryCache, split} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {PipesModule} from '@thebell/common/pipes';
import {PmefButtonComponent} from './shared/menu/pmef-button/pmef-button.component';
import {BusinessForLifeComponent} from './shared/menu/business-for-life/business-for-life.component';
import {ReloadOnNavigateModule} from '@thebell/common/directives/reload-on-navigate';
import {NotFoundComponent} from './component/not-found/not-found.component';
import {NotFoundBlockedComponent} from './component/not-found-blocked/not-found-blocked.component';
import {PostViewComponent} from './component/post-view/post-view.component';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {LayoutComponent} from './component/layout/layout.component';
import {AdvertisementComponent} from './component/advertisement';
import {isPlatformBrowser, ViewportScroller} from '@angular/common';
import {filter} from 'rxjs/operators';
import {SUBSCRIPTION_TOKEN_NAME, PRO_SUBSCRIPTION_TOKEN_NAME} from './constants';
import {
  PrivacyScriptManagerModule,
  PRIVACY_SCRIPT_MANAGER_CONFIG,
  PrivacyScriptManagerConfig,
  PrivacyScriptManager,
  ScriptControllers,
  ScriptCategory,
} from '@thebell/frontend/services/privacy-script-manager';
import { PopupQueueManagerModule, PopupQueueService } from '@thebell/frontend/services/popup-queue-manager';
import { AuthClient, AuthInterceptor } from '@thebell/common/services/api/auth/common-jwt';

const STATE_KEY = makeStateKey<any>('apolloState');

@NgModule({
  declarations: [
    AdditionalComponent,
    AuthorComponent,
    HomeComponent,
    HeaderComponent,
    AppComponent,
    MenuComponent,
    AdFoxComponent,
    PostPageComponent,
    SubscriptionPageComponent,
    SearchComponent,
    BellclubComponent,
    SubscribeConfirmedComponent,
    FooterComponent,
    FooterMenuComponent,
    InSchoolComponent,
    PostPreviewComponent,
    PmefButtonComponent,
    PostViewComponent,
    BusinessForLifeComponent,
    NotFoundComponent,
    NotFoundBlockedComponent,
    LayoutComponent,
    AdvertisementComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    TransferHttpCacheModule,
    ClientAppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonUiModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    JsonLdModule,
    PipesModule,
    ReloadOnNavigateModule,
    PrivacyScriptManagerModule,
    PopupQueueManagerModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {provide: WIDGET_DATA, useValue: []},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: Error404Interceptor, multi: true},
    {provide: ENV, useValue: environment},
    {
      provide: APOLLO_OPTIONS,
      useFactory: (
        httpLink: HttpLink,
        transferState: TransferState,
        platform: Record<string, any>,
        storage: Storage,
        cookieService: CookieService
      ) => {
        // ToDo: move to separate file
        const authLink = setContext((_, {headers}) => {
          let token = storage.getItem(SUBSCRIPTION_TOKEN_NAME) || '';
          const cookieToken = cookieService.get(SUBSCRIPTION_TOKEN_NAME);
          if (cookieToken && cookieToken !== token) {
            token = cookieToken;
            storage.setItem(SUBSCRIPTION_TOKEN_NAME, token);
          }
          let proToken = storage.getItem(PRO_SUBSCRIPTION_TOKEN_NAME) || '';
          const proCookieToken = cookieService.get(PRO_SUBSCRIPTION_TOKEN_NAME);
          if (proCookieToken && proCookieToken !== proToken) {
            proToken = proCookieToken;
            storage.setItem(PRO_SUBSCRIPTION_TOKEN_NAME, proToken);
          }
          return {
            headers: {
              ...headers,
              [SUBSCRIPTION_TOKEN_NAME]: token,
              [PRO_SUBSCRIPTION_TOKEN_NAME]: proToken,
            },
          };
        });
        const cache = new InMemoryCache();
        const isBrowser = isPlatformBrowser(platform);

        let http: any = '';

        try {
          http = httpLink.create({
            uri: environment.graphqlApi,
          });
        } catch (error) {
          console.log('An error occurred:', error);
        }

        const ws = isBrowser
          ? new WebSocketLink({
            uri: environment.graphqlWsApi,
            options: {
              reconnect: true,
            },
          })
          : null;
        const link = isBrowser
          ? split(
            ({query}) => {
              // @ts-ignore
              const {kind, operation} = getMainDefinition(query);
              return kind === 'OperationDefinition' && operation === 'subscription';
            },
            ws,
            authLink.concat(http)
          )
          : authLink.concat(http);

        if (isBrowser) {
          const state = transferState.get<any>(STATE_KEY, null);
          cache.restore(state);
        } else {
          transferState.onSerialize(STATE_KEY, () => {
            return cache.extract();
          });
        }
        const options = isBrowser ? {ssrForceFetchDelay: 200} : {ssrMode: false};
        return {cache, link, ...options};
      },
      deps: [HttpLink, TransferState, PLATFORM_ID, 'localStorage', CookieService],
    },
    {
      provide: PRIVACY_SCRIPT_MANAGER_CONFIG,
      useValue: {
        scripts: [
          new ScriptControllers.GoogleAnalytics(),
          new ScriptControllers.SimpleThirdParty('yandex-metrika', [ScriptCategory.Analytics], '/yandex-metrika.js'),
        ],
        consentWidget: {
          language: {
            translations: {
              ru: {
                consentModal: {
                  description:
                    'Мы используем cookies для улучшения работы сайта и вашего пользовательского опыта.' +
                    ' Вы можете выбрать, какие cookies разрешить, или ознакомиться с нашей' +
                    ' <a href="/privacy-policy">Политикой конфиденциальности</a> для получения дополнительной информации.'
                },
                preferencesModal: {
                  sections: [
                    {
                      description:
                        'Cookies - это небольшие текстовые файлы, которые сохраняются на компьютере, когда вы посещаете сайт. ' +
                        'Мы используем их для улучшения работы сайта и вашего пользовательского опыта.' +
                        ' Вы можете выбрать, какие cookies разрешить, или ознакомиться с нашей ' +
                        '<a href="/privacy-policy">Политикой конфиденциальности</a> для получения дополнительной информации.'
                    }
                  ]
                }
              }
            }
          }
        },
      } as PrivacyScriptManagerConfig,
    },
    PrivacyScriptManager,
    PopupQueueService,
    AuthClient
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 0]);
    router.events.pipe(filter((e) => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        });
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
