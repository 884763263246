import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PostApiService} from '@thebell/frontend/api';
import {Post} from '@thebell/data-transfer-objects';
import {CollapsePostComponent} from '@thebell/common/ui/widgets';

@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.scss'],
})
export class PostPreviewComponent implements OnInit {
  post: Post;
  injectors = [];
  collapsePostComponent = CollapsePostComponent;
  private id: number;
  private key: string;
  public postInjector: Injector;
  constructor(private route: ActivatedRoute, private api: PostApiService) {}

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    this.id = params.id;
    this.key = params.key;

    this.api.post(this.id, this.key).subscribe((post) => {
      this.post = {...post, published_at: new Date().getTime()};
    });
  }
}
