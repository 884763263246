import {GqlDto} from '../gql.dto';
import {AllowedDateType} from '../common.dto';
import {BankType, PaymentDto} from '@thebell/data-transfer-objects';
import {CouponCodeDto, CouponCodeSubscriptionPlanDto} from '../coupon-codes.dto';

export enum PaymentSubscriptionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface SubscriptionDto {
  id?: number;
  email: string;
  payerEmail: string;
  status: PaymentSubscriptionStatus;
  retryAttempts: number;
  startedAt: Date;
  nextProlongatedAt: Date;
  periodValue: number;
  periodType: AllowedDateType;
  amount: number;
  totalAmount: number;
  recurrentPaymentExternalId?: string;
  subscriptionPlanId: number;
  subscriptionPlan?: SubscriptionPlanDto | Promise<SubscriptionPlanDto>;
  payments?: PaymentDto[] | Promise<PaymentDto[]>;
  deactivatedAt?: Date;
  acquiringBank?: BankType;
  feedback?: string;
  ccspId?: number;
  ccsp?: CouponCodeSubscriptionPlanDto;
  changes?: SubscriptionPriceChangeDto[] | Promise<SubscriptionPriceChangeDto[]>;
  createdAt: Date;
  updatedAt: Date;
}

export const DateTypeToRu = {
  [AllowedDateType.YEAR]: 'год',
  [AllowedDateType.MONTH]: 'месяца',
  [AllowedDateType.WEEK]: 'недели',
  [AllowedDateType.DAY]: 'дня',
};

export enum AllowedPlanStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum AllowedProductStatus {
  INVEST = 'invest',
  BELL_PRO = 'bell_pro',
  DONATION = 'donation',
}

export enum PaymentSystem {
  STRIPE = 'stripe',
  CLOUDPAYMENTS = 'cloudpayments',
  PAYPAL = 'paypal',
  BOOSTY = 'boosty',
  BRAINTREE = 'braintree',
  FREE = 'free',
  BONUS = 'bonus',
  YOKASSA = 'yokassa'
}

export enum ConfirmationMethod {
  INTENT_ONETIME = 'intent_onetime',
  INTENT_RECURRENT = 'intent_recurrent',
  CHECKOUT_SESSION = 'checkout_session',
}

export enum Currency {
  USD = 'USD',
  RUB = 'RUB',
}

export interface SubscriptionPlanDto {
  id: number;
  name: string;
  description: string;
  status: AllowedPlanStatus;
  periodValue: number;
  periodType: AllowedDateType;
  price: number;
  createdAt: Date;
  updatedAt: Date;
  listId?: string;
  position: number;
  slug?: string;
  currency: Currency;
  paymentSystemId: PaymentSystem;
  paymentSystemPriceId: string;
  isExternalPayment: boolean;
  externalLink?: string;
  customName?: string;
  confirmationMethod?: ConfirmationMethod;
  product: AllowedProductStatus;
  comment?: string;
  couponCodeSubscriptionPlans?: CouponCodeSubscriptionPlanDto[];
  trialPeriods?: SubscriptionTrialPeriodDto[];
}

export interface SubscriptionPaymentDto {
  id: number;
  paymentId: number;
  subscriptionId: number;
}

export type SubscriptionPlanListDto = GqlDto<'subscription_plans', SubscriptionPlanDto[]>;

export enum EventsType {
  orderStatusCheck = 'orderStatusCheck',
}

export type CreateSubscription = Omit<SubscriptionDto, 'id' | 'subscriptionPlan'>;

export interface SubscriptionPriceChangeDto {
  id: number;
  subscriptionId: number;
  startDate: Date;
  endDate: Date;
  price?: number;
  originalPrice?: number;
  currency?: string;
  comment?: string;
  createdAt: Date;
  updatedAt: Date;
  oldCcspId?: number;
  ccsp?: CouponCodeSubscriptionPlanDto;
}


export enum UserChoice {
  APPLY_DISCOUNT = "APPLY_DISCOUNT",
  KEEP_SUBSCRIPTION = "KEEP_SUBSCRIPTION",
  REPORT_ISSUE = "REPORT_ISSUE",
  MANAGE_NEWSLETTERS = "MANAGE_NEWSLETTERS",
  UNSUBSCRIBE_NEWSLETTERS = "UNSUBSCRIBE_NEWSLETTERS",
  UNSUBSCRIBE_BY_EMAIL_LINK = "UNSUBSCRIBE_BY_EMAIL_LINK",
  CANCEL = "CANCEL",
}


export interface SubscriptionTrialPeriodDto {
  id: number;
  subscription: Promise<SubscriptionDto>;
  comment: string;
  periodExpires: Date;
  createdAt: Date;
  updatedAt: Date;
}


export interface SubscriptionRetentionHistoryDto {
  id: number;
  subscription: SubscriptionDto;
  userChoice: UserChoice;
  userFeedback?: string;
  comment?: string;
  createdAt: Date;
}

export interface CouponDto {
  isDiscounted: boolean;
  discountPercent: number;
  hasPromoPeriod: boolean;
  showOriginalPriceWithPromo: boolean;
  isOnlyForNew: boolean;
  promoPeriod: string;
}

export interface InvestPlanDto {
  id: number;
  priceOriginal: string;
  pricePrimaryString: string;
  priceSecondaryString: string;
  priceOriginalPerMonth: string;
  currency: string;
  alias: string;
  paymentSystemId: string;
  paymentSystemPriceId: string;
  confirmationMethod: string;
  customName: string;
  isExternal: boolean;
  externalLink: string;
  coupon?: CouponDto;
  period: string;
  periodType: string;
  periodValue: number;
}



export interface InitSubscriptionInputDto {
  email: string;
  subscriptionPlanId: number;
  token: string;
  giftRecipientEmail: string;
  bankType?: BankType;
  domain?: string;
  allowCouponCode?: boolean;
  couponCode?: string;
  cryptoData?: string;
  cardHolderName?: string;
}

export interface CreateSetupIntentInputDto {
  email: string;
  subscriptionPlanId: number;
  allowCouponCode?: boolean;
  couponCode?: string;
  giftRecipientEmail: string;
}

export interface CreateSetupIntentDto {
  confirmationToken: string;
  status: string;
  error?: {success: boolean; message: string;};
}

export interface InitSubscriptionAuthKeysDto {
  client_auth_laravel_access_token: string;
  client_auth_nest_access_token: string;
  client_auth_token_expired: string;
  client_auth_currentUser: string;
  client_auth_ad_key: string;
  client_auth_pro_key: string
}

export interface InitSubscriptionDto {
  confirmationToken: string;
  accessToken: string;
  returnUrl: string;
  threeDSecureInfo?: {
    acsUrl: string;
    paReq: string;
    transactionId: string;
    termUrl: string;
  };
  error?: {success: boolean; message: string;};
}

export interface RequestAccessSubscriptionDto {
  email: string;
  slug: string;
  domain?: string;
  token: string;
}

export interface CreateSubscriptionPlanInputDto {
  price: number;
  periodValue: number;
  periodType: AllowedDateType;
  promoPeriod?: number;
  promoPeriodType?: AllowedDateType;
  name: string;
  description: string;
  listId: string;
  position?: number;
  slug: string;
}

export interface ProlongateSubscriptionInputDto {
  startDate: Date;
  endDate: Date;
  resultDate: Date;
}
